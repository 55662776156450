import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { HashRouter } from "react-router-dom";
import ErrorFallback from "./Sentry";
// import Loader from "./screens/CommonComponent/Loader";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_BUILD == "prod") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_BUILD,
    ignoreUrls: [
      "https://maps.googleapis.com/maps-api-v3/api/js/35/10a/poly.js",
    ],
  });
}
document.body.classList.add("safeco-app");

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorFallback()}>
      <HashRouter>
        <App />
      </HashRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
